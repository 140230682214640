import axios from 'axios';
import Auth from 'helpers/utils/cognito';
import { addToCarts } from 'redux/reducers/product-cart';
import { store } from 'redux/store'; // Import your store

/**
 * Retrieves the current user's access token from the authentication session.
 * @returns {Promise<string | null>} The access token as a string, or null if unavailable.
 */
const getAccessToken = async () => {
  const session = await Auth.getSession();
  return session?.getAccessToken()?.getJwtToken();
};

/**
 * Fetches all active user carts (reservations) from the Jane API, handling pagination internally.
 * Filters only reservations with status "pending" and formats the response as an object where {store_id: uuid}.
 * @returns {Promise<Object>} The formatted active carts object.
 * @throws {Error} If the request fails, an error is thrown with a message.
 */
export const fetchActiveCarts = async () => {
  const accessToken = await getAccessToken();

  if (!accessToken) {
    return null;
  }

  try {
    // Create an axios instance for interacting with the Jane API's carts endpoint
    const externalUserApiClient = axios.create({
      baseURL: process.env.NEXT_PUBLIC_IHJ_PROXY_HOST + '/roots/external_user_api/v1',
      headers: {
        Accept: 'application/json',
        Authorization: `Bearer ${accessToken}`,
        'Content-Type': 'application/json',
      },
    });

    const activeCarts = {};
    let page = 1;
    let hasMorePages = true;

    while (hasMorePages) {
      const { data } = await externalUserApiClient.get('/carts', {
        params: {
          pageSize: 100,
          'reservation_status[]': 'pending',
          page,
        },
      });

      const { reservations } = data?.data || [];

      // Loop through each reservation and store it in the activeCarts object
      reservations.forEach(({ store_id, uuid }) => {
        activeCarts[store_id] = uuid;
      });

      // Check if there are more pages
      hasMorePages = data.hasNextPage;
      page += 1;
    }

    return activeCarts;
  } catch (error) {
    throw new Error(`Failed to fetch active carts: ${error.message}`);
  }
};

/**
 * Fetches a specific cart by ID from the Jane API.
 * @param {string} cartId - The ID of the cart to fetch.
 * @returns {Promise<Object | null>} The cart data, or null if an error occurs.
 */
export const fetchCartById = async (cartId) => {
  const accessToken = await getAccessToken();

  if (!accessToken) {
    return null;
  }

  try {
    // Create an axios instance for interacting with the Jane API's carts endpoint
    const client = axios.create({
      baseURL: process.env.NEXT_PUBLIC_IHJ_PROXY_HOST + '/roots/carts_api/v1/carts/',
      headers: {
        Accept: 'application/json',
        Authorization: `Bearer ${accessToken}`,
        'Content-Type': 'application/json',
      },
    });

    // Fetch the cart by ID
    const { data } = await client.get(cartId);

    return data?.data;
  } catch (error) {
    // Log the error and return null instead of throwing an error
    return null;
  }
};

/**
 * Fetches the latest cart by ID from the Jane API and updates the Redux store with the fetched cart data.
 * Dispatches the action to add or update the cart for the corresponding store.
 *
 * @returns {Promise<void>} Resolves when the cart is fetched and Redux is updated.
 * @throws Will log an error if fetching the cart or updating the Redux store fails.
 */
export const updateCartInRedux = async (cart) => {
  try {
    if (cart) {
      const { store_id, cart_uuid, items } = cart;
      store.dispatch(
        addToCarts({
          store_id,
          cart: {
            cart_uuid,
            items,
            discountPrice: 0,
            subTotal: 0,
          },
        }),
      );
    }
  } catch (error) {
    console.error(`[CART] Error updating cart in redux:`, error);
  }
};

/**
 * Syncs the user's current Redux cart with the Jane API.
 * If a cart exists for the store in Redux, it will be updated with the latest data from Jane.
 * If no cart exists in Redux, it will check for an active cart in Jane and update Redux accordingly.
 *
 * @returns {Promise<void>} Resolves when the sync operation completes.
 * @throws Will log any errors encountered during the sync process.
 */
export const syncCarts = async () => {
  const state = store.getState();
  const storeId = state.dispensaryList.currentDispensary.dispensaries.storeId;
  const currentCart = state.cart.carts[storeId];
  try {
    if (currentCart) {
      const latestCart = await fetchCartById(currentCart.cart_uuid); // Fetch latest cart data from jane
      if (latestCart) {
        await updateCartInRedux(latestCart); // Update redux cart
      }
    }
  } catch (error) {
    console.error('[CART] Error syncing carts:', error);
  }
};
